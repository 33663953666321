.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"]
  display inline-block
  padding 20px 60px
  color black
  text-align center
  font-size 14px
  font-weight 600
  text-transform uppercase
  text-decoration none
  white-space nowrap
  background-color white
  border-radius 4px
  cursor pointer
  box-sizing border-box
