.intro
  background-image url('/assets/img/intro__bg.jpg')
  +maxw($small-max)
    position relative
    height 860px
    background-image url('/assets/img/sp/intro__bg.jpg')
    background-position bottom center
  +w(375px, $small-max)
    height 795px
  +w(400px, $small-max)
    height 823px
  +w(485px, $small-max)
    height 850px
  +w(550px, $small-max)
    height 890px
  +w(630px, $small-max)
    height 910px
  +w(690px, $small-max)
    height 970px
  &__title
    position relative
    top 15.2vh
    +maxw($small-max)
      top 80px
  &__text
    position absolute
    top 39vh
    right 7vw
    width 43vw
    +maxw($small-max)
      width 90vw
      top 164px
      right initial
      left 19px
    &__title
      font-size 46px
      font-weight 700
      font-style italic
      margin 0
      line-height 1.1
      +maxw($small-max)
        font-size 32px
    &__body
      font-size 15px
      margin 23px 0 0 0
      line-height 1.7
      +maxw($small-max)
        font-size 13px
        margin 12px 0 0 0
        line-height 1.65