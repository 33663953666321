.hero
  +maxw($small-max)
    padding-top 59px
  &__inner
    position relative
    top 0
    left 0
    width 100%
    height 100%
    background-position center
    background-size cover
    background-image url('/assets/img/hero__bg.jpg')
    +maxw($small-max)
      background-image url('/assets/img/sp/hero__bg.jpg')
  &__title
    position absolute
    bottom 5%
    left 0
    right 0
    margin 0 auto
    width 84%
    text-align center
    margin 0 auto
    opacity 0
    transition opacity 1.8s ease
    transition-delay .7s
    &.active
      opacity 1
    +maxw($small-max)
      width 78%
      margin 0
      right initial
      bottom initial
      top 50%
      transform translateY(-50%)
    &__text
      display block
      margin 0
      font-size 104px
      font-style italic
      font-weight 700
      line-height 1
      text-shadow 0 0 52px rgba(0, 0, 0, .3)
      color $color-white
    picture
      width 100%
  &__preload-bg
    display none