.footer
  padding 33px 0 36px 0
  background-color $color-bg-blue
  color $color-white
  +maxw($small-max)
    padding 41px 0 41px 0
  &__top
    margin-bottom 1px
    +maxw($small-max)
      margin-bottom 42px
    &__inner
      display flex
      justify-content flex-end
      align-items flex-end
      +maxw($small-max)
        display block
  &__middle
    border-bottom 1px solid $color-border-footer
    padding-bottom 50px
    margin-bottom 17px
    +maxw($small-max)
      padding-bottom 42px
    &__inner
      display flex
      justify-content space-between
      align-items flex-end
      +maxw($small-max)
        flex-direction column-reverse
        align-items center
  &__bottom
    text-align center
    &__inner
      display flex
      justify-content space-between
      align-items flex-end
      +maxw($small-max)
        padding 0 15px
      +maxw(990px)
        display block
  &__nav
    +maxw($small-max)
      display flex
      flex-direction column
      align-items center
    &__link
      color $color-white
      margin-left 5.4em
      font-family $font-family-alt
      font-size $font-size-small
      letter-spacing .02em
      &:first-child
        margin-left 0
      +maxw($small-max)
        margin 24px 0 0 0
        font-size 17px
        &:first-child
          margin-top 0
  &__logo
    margin-top 1px
    +maxw($small-max)
      text-align center
    &__img
      width 250px
      +maxw($small-max)
        width 176px
    &__subtitle
      font-size 13px
      margin 15px 0 0 0
      letter-spacing .03em
      +maxw($small-max)
        font-size 9px
        margin-top 7px
    &__link
      position relative
      display inline-block
      margin-top 32px
      padding-right 1.5em
      color $color-white
      font-size 16px
      &:before
        content ''
        position absolute
        top 50%
        transform translateY(-50%)
        right 0
        width 6px
        height 10px
        background transparent url('/assets/img/arrow--footer.png') no-repeat 0 0
        background-size 6px 10px
      +maxw($small-max)
        font-size 13px
        margin-top 31px
  &__sns
    margin-bottom 17px
    +maxw($small-max)
      margin-bottom 47px
    &__top
      +maxw($small-max)
        text-align center
    &__bottom
      margin-top 15px
      +maxw($small-max)
        margin-top 18px
    &__link
      display inline-block
      color $color-white
      margin-left 36px
      +maxw($small-max)
        &--tw
          width 34px
        &--ig
          width 27px
        &--fb
          width 27px
      &:first-child
        margin-left 0
    &__btn
      max-width 190px
      font-size 13px
      font-weight 700
      font-style normal
      padding-top 5px
      padding-bottom 5px
      letter-spacing 0
      +minw($small)
        &:hover
          border-color $color-white
      +maxw($small-max)
        max-width 198px
        background-color $color-white
        color $color-primary
        padding-top 7px
        padding-bottom 7px
  &__outbound-nav
    text-align left
    +maxw(990px)
      text-align center
    &__link
      position relative
      color $color-white
      margin-right 4.5em
      padding-right 1.95em
      font-size 11px
      white-space nowrap
      +maxw($small-max)
        font-size 10px
        padding-right 0.95em
        margin-right 4.1em
      &:last-child
        margin-right 0
      &:before
        content ''
        position absolute
        top 50%
        transform translateY(-50%)
        right 0
        width 4px
        height 7px
        background transparent url('/assets/img/arrow--footer.png') no-repeat 0 0
        background-size 4px 7px
      sup
        font-size 7Px
  &__copyright
    display flex
    margin 0 0 3px 0
    +maxw(990px)
      display block
      text-align center
      margin 17px
    +maxw($small-max)
      margin-top 17px
    &__text
      margin 0 0 0 5.6em
      text-align center
      font-size 9px
      +maxw($page-wrapper)
        margin-left 4.35vw
      +maxw(990px)
        margin 8px 0 0 0
      &:first-child
        margin-left 0