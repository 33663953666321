.nav-pc
  display flex
  justify-content space-between
  align-items center
  &__logo
    display block
    width 134px
  &__list
    display block
  &__item
    display inline-block
    margin-left 2.9em
    &:first-child
      margin-left 0
  &__link
    position relative
    color $color-white
    font-family $font-family-alt
    font-weight 400
    transition color .5s ease-in-out
    &:before, &:after
      content ''
      display block
      position absolute
      bottom -6px
      height 2px
      width 0
      background-color $color-white
    &:before
      right 0
      transition width 0.4s cubic-bezier(0.29, 0.18, 0.26, 0.83)
    &:after
      left 0
    &:hover
      opacity 1
      &:before
        width 100%
        left 0
    &--sns
      margin-left 14px
      hover-text-opacity()
      &:first-child
        margin-left 0
      &:before, &:after
        display none

.header-pc--sticky
  .nav-pc
    &__link
      color $color-text
      &:before, &:after
        background-color $color-text

.nav-sp
  display flex
  justify-content space-between
  padding 17px 0
  &__logo
    width 92.5px
  &__toggle
    position absolute
    top 50%
    transform translateY(-50%)
    right 0
    display block
    width 25px
    height 21px
    color $color-secondary
    transition color .5s ease-in-out
    outline 0
    -webkit-tap-highlight-color rgba(0,0,0,0)
    -webkit-tap-highlight-color transparent
    &__wrapper
      position relative
      flex-grow 1
      text-align right
    &__bar
      display block
      position absolute
      width 100%
      height 2px
      left 0
      background-color $color-black
      transition all .25s ease-in-out
      &:first-child
        top 0
        transition-delay .5s
      &:nth-child(2)
        top 50%
        transform translateY(-50%)
        transition-delay .625s
      &:last-child
        bottom 0
        transition-delay .75s
  &__overlay
    display block
    position fixed
    top -100vh
    right 0
    width 100%
    height 62.5vh
    background-color $color-bg
    z-index 1000
    transition top .8s ease-in-out
    +minw($small)
      display none
    &__logo
      width 92.5px
      opacity 0
      transition opacity .5s ease-in-out
      transition-delay 1.25s
      &.active
        opacity 1
    &__top
      position relative
      display flex
      justify-content space-between
      padding 17px 0
    &__close
      display block
      position absolute
      top 18px
      right 0
      width 25px
      height 26px
      display block
      outline 0
      color $color-black
      transform rotate(45deg)
      -webkit-tap-highlight-color rgba(0,0,0,0)
      -webkit-tap-highlight-color transparent
      &__bar
        position absolute
        display block
        background-color $color-black
        transition all .25s ease-in-out
        &:first-child
          transition-delay 1s
          height 0
          width 2px
          left 11px
        &:last-child
          transition-delay 1.3s
          height 2px
          width 0
          top 11px
    &__inner
      position absolute
      top 50%
      transform translateY(-50%)
      left 0
      right 0
      margin 19px auto 0 auto
      text-align center
    &__link
      display block
      margin-top 3.5vh
      font-family $font-family-alt
      font-size 19px
      font-weight 400
      color $color-black
      opacity 0
      transform translateX(2rem)
      -webkit-tap-highlight-color rgba(0,0,0,0)
      -webkit-tap-highlight-color transparent
      &:hover, &:active, &:focus
        color $color-black
        opacity 0
      &:first-child
        margin-top 0
    &__sns
      display flex
      justify-content center
      margin-top 7.4vh
      opacity 0
      transform translateX(2rem)
      &__link
        margin-left 30px
        &:first-child
          margin-left -7px
    &.active
      top 0
      .nav-sp__overlay
        &__link
          animation mobileNavItemsSlideIn 300ms ease forwards
          &:nth-child(1)
            animation-delay 0.9s
          &:nth-child(2)
            animation-delay 0.95s
          &:nth-child(3)
            animation-delay 1.0s
          &:nth-child(4)
            animation-delay 1.05s
          &:nth-child(5)
            animation-delay 1.1s
          &:nth-child(6)
            animation-delay 1.15s
        &__sns
          animation mobileNavItemsSlideIn 300ms ease forwards
          animation-delay 1.2s
        &__close
          &__bar
            &:first-child
              height 100%
            &:last-child
              width 100%
      .nav-sp__overlay__logo
        opacity 1