.section
  position relative
  background-color $color-white
  .fixed
    position fixed
    top 0
    left 0
  &--full
    height 100vh
    background-size cover
    background-position center
  &--sticky
    position sticky
    top 0
    left 0
    z-index 1
    &.unset
      position relative
  &--wipe
    position relative
    width 100%
    top 0
    left 0
    margin-bottom -100px
  &__wipe
    position relative
    height 100vh
    width 100%
    z-index 1
    &--strawberry
      z-index 5
      .section__wipe__img
        background-image url('/assets/img/wipe--strawberry.jpg')
        +maxw($small-max)
          background-image url('/assets/img/sp/wipe--strawberry.jpg')
    &--white-peach
      z-index 4
      .section__wipe__img
        background-image url('/assets/img/wipe--white-peach.jpg')
        +maxw($small-max)
          background-image url('/assets/img/sp/wipe--white-peach.jpg')
    &--original
      z-index 3
      .section__wipe__img
        background-image url('/assets/img/wipe--original.jpg')
        +maxw($small-max)
          background-image url('/assets/img/sp/wipe--original.jpg')
    &--mango
      z-index 2
      .section__wipe__img
        background-image url('/assets/img/wipe--mango.jpg')
        +maxw($small-max)
          background-image url('/assets/img/sp/wipe--mango.jpg')
    &--lychee
      z-index 1
      .section__wipe__img
        background-image url('/assets/img/wipe--lychee.jpg')
        +maxw($small-max)
          background-image url('/assets/img/sp/wipe--lychee.jpg')
    &__inner
      position sticky
      top 0
      left 0
      height 100%
      width 100%
      min-height 0
      max-height 100vh
      overflow hidden
    &__img
      position relative
      top 0
      left 0
      height 100vh
      width 100%
      background-size cover
      background-position center center
      background-repeat no-repeat
      +maxw($small-max)
        background-position bottom center
    &__text
      position absolute
      top 104px
      left 9.6vw
      font-size 82px
      font-style italic
      font-weight 700
      line-height 1
      text-shadow 0 0 56.16px rgba(225, 220, 184, 0.3)
      margin 0
      z-index 1
      +safariOnly()
        text-shadow 0 0 10px rgba(225, 220, 184, 0.3)
      +maxw($page-wrapper)
        font-size 7.08118vw
      +maxw($small-max)
        top 81px
        left 19px
        font-size 50px
        font-size 13.3336vw
        line-height 1.1
      &--original
        color $color-text-original
      &--mango
        color $color-text-mango
      &--lychee
        color $color-text-lychee
      &--white-peach
        color $color-text-white-peach
      &--strawberry
        color $color-text-strawberry