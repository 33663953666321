.products
  padding 117px 0 170px 0
  +maxw($small-max)
    padding 79px 0 102px 0
  .wrapper
    +maxw($small-max)
      padding 0 6px 0 6px
  &__section
    margin-top 18px
    &__title
      text-align center
      margin 0
      font-size 24px
      +maxw($small-max)
        font-size 16px
        margin-top 24px
    &__subtitle
      text-align center
      font-size $font-size-small
      margin 4px 0 0 0
      +maxw($small-max)
        font-size 13px
        margin-top 2px
  &__sliders
    display flex
    flex-wrap wrap
    align-items stretch
    margin 69px 0 0 0
    +maxw($small-max)
      margin-top 41px
    &:first-child
      margin-top 0
    &__wrapper
      margin-top 23px
      +maxw($small-max)
        margin-top 8px
  &__slider
    display flex
    flex-direction column
    width percentage(1/3)
    padding 33px 3px 0 3px
    +maxw($small-max)
      width percentage(1/2)
      padding-top 29px
    &:nth-child(1), &:nth-child(2), &:nth-child(3)
      padding-top 0
      +maxw($small-max)
        padding-top 29px
        &:nth-child(1), &:nth-child(2)
          padding-top 0
    &__inner
      margin-top auto
    &__title
      margin 0 0 5px 0
      font-size 24px
      text-align center
      +maxw($small-max)
        font-size 16px
        margin-bottom 1px
    &__top
      margin-bottom 23px
      +maxw($small-max)
        margin-bottom 10px
    &__subtitle
      margin 0
      font-size $font-size-small
      text-align center
      +maxw($small-max)
        font-size 13px
    &__caption
      text-align center
      margin 15px 0 0 0
      font-size 15px
      letter-spacing .04em
      +maxw($small-max)
        font-size 12px
        margin-top 11px
  &__slide
    position relative
    &--border
      padding 1px
      &>img, .products__slide__desc
        border 1px solid $color-border-slide
    &__desc
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      padding 36px 44px
      background-color alpha($color-white, .9)
      +maxw($small-max)
        padding 14px 15px
      &--narrow
        padding-top 27px
        padding-bottom 27px
        +maxw($small-max)
          padding 14px 15px
      &--original
        background-color alpha($color-bg-original, .9)
      &--mango
        background-color alpha($color-bg-mango, .9)
      &--lychee
        background-color alpha($color-bg-lychee, .9)
        .products__slide__desc__title,
        .products__slide__desc__text
          color $color-bg-white
      &--white-peach
        background-color alpha($color-bg-white-peach, .9)
      &--strawberry
        background-color alpha($color-bg-strawberry, .9)
      &__inner
        height 100%
      &__title
        font-size 15px
        color $color-text
        font-weight 700
        margin 0
        +maxw($page-wrapper)
          font-size 1.295335vw
        +maxw($small-max)
          font-size 11px
          font-size 2.93335vw
          text-align center
      &__text
        font-size 15px
        color $color-text
        margin 12px 0 0 0
        line-height 1.35
        +maxw($page-wrapper)
          font-size 1.295335vw
        +maxw($small-max)
          font-size 11px
          font-size 2.93335vw
          margin-top 9px
          line-height 1.4
        &--concentrate
          +maxw($small-max)
            font-size 2.63335vw
            margin-top 6px
        &--mango
          +maxw($small-max)
            font-size 2.67vw
        &--strawberry
          +maxw($small-max)
            font-size 2.67vw
        &__img
          margin-top 5px
          +maxw($page-wrapper)
            width 84%
          +maxw(548px)
            width 65%
    .button
      margin-top 20px
      border-color $color-black
      border-width 1px
      width 100%
      font-size 16px
      color $color-black
      background-color transparent
      text-transform capitalize
      padding 8px
      transition color $transition-time-text
      +minw($small)
        &:hover
          color lighten($color-black, 20%)
      +maxw($small-max)
        font-size 2.93335vw
        padding 2px
        margin-top 3px
  &__cta
    margin-top 73px
    +maxw($small-max)
      margin-top 44px
      padding 0 15px