.header-pc
  position fixed
  top 0
  left 0
  width 100%
  padding 18px 0
  z-index 100
  transition top .4s ease
  +maxw($small-max)
    display none
  &--sticky
    top -97px
    background-color $color-white
    &.active
      top 0

.header-sp
  position fixed
  top 0
  left 0
  width 100%
  background-color $color-white
  z-index 100
  +minw($small)
    display none