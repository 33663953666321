.faq
  padding 14px 0 0 0
  +maxw($small-max)
    padding 40px 0 0 0
  &__section
    &__title
      font-family $font-family
      font-style italic
      font-size 36px
      text-align center
      margin 15px 0 0 0
      letter-spacing .028em
      +maxw($small-max)
        font-size 23px
        margin-top 24px
    &__subtitle
      text-align center

  &__acrs
    margin-top 49px
    +maxw($small-max)
      margin-top 27px
  &__acr
    border-top 1px solid $color-border-acr
    padding-top 25px
    margin-top 25px
    +maxw($small-max)
      padding-top 15px
      margin-top 15px
    &:first-child
      border-top none
      padding-top 0
      margin-top 0
    &:last-child
      border-bottom 1px solid $color-border-acr
      padding-bottom 25px
      +maxw($small-max)
        padding-bottom 15px
    &__title
      position relative
      margin 0
      font-family $font-family
      font-size 18px
      font-weight 400
      padding-right 22px
      color $color-text
      cursor pointer
      +minw($small)
        hover-text-color($color-primary)
      +maxw($small-max)
        font-size 16px
        line-height 1.5
        -webkit-tap-highlight-color rgba(0,0,0,0)
        -webkit-tap-highlight-color transparent
      &:before, &:after
        content ''
        position absolute
        top 11px
        right 0
        height 1px
        width 16px
        transform rotate(180deg)
        background-color $color-text
        transition transform .3s ease
      &:after
        transform-origin center
        transform rotate(90deg)
      &.active
        &:after
          transform rotate(0)
        &:before
          transform rotate(0)
      sup
        top 0
    &__body
      display none
      font-size 16px
      margin 27px 0 0 0
      line-height 1.9
      +maxw($small-max)
        margin-top 15px
        font-size 13px
        line-height 1.65
      &--small
        font-size 12px
        line-height 1.7
        letter-spacing .054em
        margin-top 9px
        +maxw($small-max)
          font-size 13px