.heading
  &-section
    display flex
    align-items center
    justify-content center
    text-align center
    font-family $font-family-alt
    font-size $font-size-base
    font-weight 400
    margin 0
    height 77px
    background transparent url('/assets/img/heading__bg2.png') no-repeat center center
    +maxw($small-max)
      font-size $font-size-smaller
      height 63px
      background-size 18px 63px
    span
      position relative
      top 4px
      padding 0 18px 0 19px
      letter-spacing .06em
      &:before, &:after
        content ''
        display block
        position absolute
        top 45%
        transform translateY(-50%)
        left 0
        width 13px
        height 18px
        background transparent url('/assets/img/heading__lines.png') no-repeat 0 0
        background-size 13px 18px
        +maxw($small-max)
          width 11px
          height 15px
          background-size 11px 15px
      &:after
        transform translateY(-50%) scaleX(-1)
        left initial
        right 0
      sup
        font-size 8Px
    &--bottle-darker
      background transparent url('/assets/img/heading__bg1.png') no-repeat center center
      +maxw($small-max)
        background-size 18px 63px