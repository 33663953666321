// Clearfix
clearfix()
  &:before,
  &:after
    content ' '
    display table

  &:after
    clear both

// Hover
hover-image(transitionVal = $transition-time-img, alphaVal = $opacity-default)
  transition opacity transitionVal
  &:hover, &:focus, &:active
    opacity alphaVal

hover-text-opacity(alphaVal = $opacity-default, transitionVal = $transition-time-text)
  opacity 1
  transition opacity transitionVal
  &:hover, &:focus, &:active
    opacity alphaVal

hover-text-color(colorVal = $color-primary, transitionVal = $transition-time-text)
  transition color transitionVal
  &:hover, &:focus, &:active
    color colorVal

remove-hover-text()
  transition none
  border none
  &:hover, &:focus, &:active
    opacity 1

remove-hover-image()
  transition none
  &:hover, &:focus, &:active
    opacity 1

remove-hover-text-color(colorVal = $color-text)
  transition none
  &:hover, &:focus, &:active
    color colorVal