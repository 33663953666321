.button
  display inline-block
  padding 13.5px
  width 443px
  max-width 100%
  border 2px solid $color-border-btn
  border-radius 33px
  font-family $font-family
  font-size 22px
  font-style italic
  font-weight 500
  color $color-primary
  letter-spacing .06em
  transition all .3s ease-in-out
  +minw($small)
    &:hover
      background-color $color-primary
      color $color-white
  +maxw($small-max)
    font-size 17px
    border-radius 27.5px
    padding 12px
    background-color $color-primary
    color $color-white
  &__wrapper
    text-align center