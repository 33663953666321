.bookmark
  position relative
  top -73px
  +maxw($small-max)
    top -60px
  &--locations
    top -240px
    +maxw($small-max)
      top -105px
  &--faq
    top -100px
    +maxw($small-max)
      top -80px