.locations
  background $color-white url('/assets/img/locations__bg1-slice.jpg') repeat-x 0 0
  background-size 1px 860px
  +maxw($small-max)
    // background $color-white url('/assets/img/sp/locations__bg1-slice.jpg') repeat-x 0 0
    // background-size 1px 241px
    background $color-white
  &__bg
    background transparent url('/assets/img/locations__bg1.jpg') no-repeat 50% 0
    background-size 1400px 860px
    +maxw($small-max)
      background transparent url('/assets/img/sp/locations__bg1.jpg') no-repeat 50% 0
      //background-size 375px 241px
      background-size contain
  &__inner
    position relative
    top -155px
    margin-bottom -52px
    padding 528px 0 0 0
    background transparent url('/assets/img/locations__bg2.png') no-repeat top center
    background-size 1400px 695px
    +maxw($small-max)
      top -39px
      padding-top 51vw
      padding-top 191px
      margin-bottom 0
      background transparent url('/assets/img/sp/locations__bg2.png') no-repeat top center
      background-size 375px 195px
    .wrapper
      +maxw($small-max)
        padding 0 9px 0 9px
  &__section
    background-color $color-white
    padding 43px 70px 0 70px
    border-radius 50px
    +maxw($small-max)
      border-radius 20px
      padding 30px 11px 0 11px
    &__title
      font-family $font-family
      font-style italic
      font-size 36px
      text-align center
      margin 15px 0 0 0
      letter-spacing .028em
      +maxw($small-max)
        font-size 23px
        margin-top 24px
  &__locator
    margin 32px 0 0 0
    +maxw($small-max)
      margin-top 20px
    &__widget
      width 100%
  &__cta
    margin-top 60px
    +maxw($small-max)
      margin-top 51px