.instagram
  padding 117px 0 140px 0
  +maxw($small-max)
    padding 78px 0 107px 0
  .wrapper
    +maxw($small-max)
      padding 0 9px 0 9px
  &__section
    &__title
      font-family $font-family
      font-style italic
      font-size 36px
      text-align center
      margin 15px 0 0 0
      letter-spacing .028em
      +maxw($small-max)
        font-size 23px
        margin-top 24px
  &__feed
    display grid
    margin 31px 0 0 0
    grid-template-columns repeat(5, 1fr)
    grid-template-rows repeat(4, 1fr)
    grid-gap 10px
    +ie10andAbove()
      display flex
      flex-wrap wrap
      grid-gap 0
    +maxw($small-max)
      display flex
      flex-wrap wrap
      grid-gap 0
      margin-top 22px
    a
      display block
      border 0
      height 217.59px
      +maxw($page-wrapper)
        height 18.791vw
      +ie10andAbove()
        flex-basis initial
        width percentage(1/5)
        padding 5px
      &:nth-child(1)
        grid-column 1 / 3
        grid-row 1 / 3
        height 445.19px
        +maxw($page-wrapper)
          height 38.445vw
        +ie10andAbove()
          height 217.59px
      &:nth-child(14)
        grid-column 4 / 6
        grid-row 3 / 5
        height 445.19px
        +maxw($page-wrapper)
          height 38.445vw
        +ie10andAbove()
          height 217.59px
      &:nth-child(15)
        display none
        +ie10andAbove()
          display block
      +maxw($small-max)
        display none
        flex-basis percentage(1/2)
        padding 2.5px
        height 48.2vw !important
        +ie10andAbove()
          flex-basis initial
          width percentage(1/2)
        &:nth-child(odd)
          padding-left 0
        &:nth-child(even)
          padding-right 0
        &:nth-child(-n+6)
          display block
        &:nth-child(15)
          +ie10andAbove()
            display none

    img
      display block
      width 100%
      height 100%
      object-fit cover