@media screen
  #preloader
    position absolute
    left -9999px
    top  -9999px
  #preloader img, #preloader picture
    display block

@media print
  #preloader,
  #preloader img, #preloader picture
    visibility hidden
    display none