html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}
border 0 {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquotebefore,
blockquoteafter,
qbefore,
qafter {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
main {
  display: block;
}
html {
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  overflow-x: hidden;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
img {
  height: auto;
  max-width: 100%;
}
picture {
  display: inline-block;
}
html {
  font-size: 16px;
}
body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #262626;
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}
.gigantic,
.huge,
.large,
.bigger,
.big,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.414em 0 0.5em;
  color: #262626;
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
.huge,
h1 {
  font-size: 39.056px;
  margin-top: 0;
}
@media (max-width: 767px) {
  .huge,
  h1 {
    font-size: 33.184px;
  }
}
.large,
h2 {
  font-size: 31.248px;
}
@media (max-width: 767px) {
  .large,
  h2 {
    font-size: 27.648px;
  }
}
.bigger,
h3 {
  font-size: 25.008px;
}
@media (max-width: 767px) {
  .bigger,
  h3 {
    font-size: 23.04px;
  }
}
.big,
h4 {
  font-size: 20px;
}
@media (max-width: 767px) {
  .big,
  h4 {
    font-size: 19.2px;
  }
}
.small,
small {
  font-size: 12.8px;
}
@media (max-width: 767px) {
  .small,
  small {
    font-size: 13.328px;
  }
}
p {
  margin: 0 0 1.3em 0;
  font-weight: 400;
}
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
sup {
  position: relative;
  margin-left: 0.15em;
  top: 0.18em;
  font-size: 0.5em;
}
::selection {
  background: #7fb5ff;
}
::-moz-selection {
  background: #7fb5ff;
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}
body {
  -webkit-tap-highlight-color: #7fb5ff;
}
a {
  color: #004098;
  text-decoration: none;
  outline: 0;
  border-bottom: 1px solid transparent;
  opacity: 1;
  transition: opacity 0.3s;
}
a:hover,
a:focus,
a:active {
  opacity: 0.8;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #262626;
}
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  padding: 20px 60px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
@-moz-keyframes mobileNavItemsSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes mobileNavItemsSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes mobileNavItemsSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes mobileNavItemsSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wrapper {
  width: 100%;
  max-width: 1158px;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .wrapper {
    padding: 0 20px;
  }
}
.section {
  position: relative;
  background-color: #fff;
}
.section .fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.section--full {
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.section--sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.section--sticky.unset {
  position: relative;
}
.section--wipe {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  margin-bottom: -100px;
}
.section__wipe {
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 1;
}
.section__wipe--strawberry {
  z-index: 5;
}
.section__wipe--strawberry .section__wipe__img {
  background-image: url("/assets/img/wipe--strawberry.jpg");
}
@media (max-width: 767px) {
  .section__wipe--strawberry .section__wipe__img {
    background-image: url("/assets/img/sp/wipe--strawberry.jpg");
  }
}
.section__wipe--white-peach {
  z-index: 4;
}
.section__wipe--white-peach .section__wipe__img {
  background-image: url("/assets/img/wipe--white-peach.jpg");
}
@media (max-width: 767px) {
  .section__wipe--white-peach .section__wipe__img {
    background-image: url("/assets/img/sp/wipe--white-peach.jpg");
  }
}
.section__wipe--original {
  z-index: 3;
}
.section__wipe--original .section__wipe__img {
  background-image: url("/assets/img/wipe--original.jpg");
}
@media (max-width: 767px) {
  .section__wipe--original .section__wipe__img {
    background-image: url("/assets/img/sp/wipe--original.jpg");
  }
}
.section__wipe--mango {
  z-index: 2;
}
.section__wipe--mango .section__wipe__img {
  background-image: url("/assets/img/wipe--mango.jpg");
}
@media (max-width: 767px) {
  .section__wipe--mango .section__wipe__img {
    background-image: url("/assets/img/sp/wipe--mango.jpg");
  }
}
.section__wipe--lychee {
  z-index: 1;
}
.section__wipe--lychee .section__wipe__img {
  background-image: url("/assets/img/wipe--lychee.jpg");
}
@media (max-width: 767px) {
  .section__wipe--lychee .section__wipe__img {
    background-image: url("/assets/img/sp/wipe--lychee.jpg");
  }
}
.section__wipe__inner {
  position: sticky;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 0;
  max-height: 100vh;
  overflow: hidden;
}
.section__wipe__img {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .section__wipe__img {
    background-position: bottom center;
  }
}
.section__wipe__text {
  position: absolute;
  top: 104px;
  left: 9.6vw;
  font-size: 82px;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 0 56.16px rgba(225,220,184,0.3);
  margin: 0;
  z-index: 1;
}
@supports (background: -webkit-canvas(squares)) {
  .section__wipe__text {
    text-shadow: 0 0 10px rgba(225,220,184,0.3);
  }
}
@media (max-width: 1158px) {
  .section__wipe__text {
    font-size: 7.08118vw;
  }
}
@media (max-width: 767px) {
  .section__wipe__text {
    top: 81px;
    left: 19px;
    font-size: 50px;
    font-size: 13.3336vw;
    line-height: 1.1;
  }
}
.section__wipe__text--original {
  color: #fff;
}
.section__wipe__text--mango {
  color: #fff;
}
.section__wipe__text--lychee {
  color: #fff;
}
.section__wipe__text--white-peach {
  color: #fff;
}
.section__wipe__text--strawberry {
  color: #fff;
}
@media screen {
  #preloader {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  #preloader img,
  #preloader picture {
    display: block;
  }
}
@media print {
  #preloader,
  #preloader img,
  #preloader picture {
    visibility: hidden;
    display: none;
  }
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 46%;
  display: block;
  width: 21px;
  height: 52px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 767px) {
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 20px;
    top: 15px;
  }
}
.slick-prev:hover,
.slick-next:hover,
color transparent {
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
opacity 1,
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  box-shadow: 1px -1px 0 1px #4e4e4e inset;
  -webkit-box-shadow: 1.5px -1.5px #4e4e4e inset;
  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  transition: 0.2s;
  transform: rotate(225deg);
  opacity: 0.8;
}
@media (max-width: 767px) {
  .slick-prev:before,
  .slick-next:before {
    left: initial;
    right: 0;
    top: 5px;
    width: 16px;
    height: 10px;
    padding: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    border: 0;
    transform: none;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 16px 10px;
    background-image: url("/assets/img/sp/slider__arrow.png");
  }
}
.slick-prev:before {
  transform: rotate(45deg);
  left: initial;
  right: 0;
}
@media (max-width: 767px) {
  .slick-prev:before {
    transform: scaleX(-1);
    right: initial;
    left: 0;
  }
}
.slick-prev {
  left: 5px;
}
@media (max-width: 767px) {
  .slick-prev {
    left: 10px;
  }
}
[dir='rtl'] .slick-prev {
  right: 5px;
  left: auto;
}
@media (max-width: 767px) {
  [dir='rtl'] .slick-prev {
    left: 10px;
  }
}
.slick-next {
  right: 5px;
}
@media (max-width: 767px) {
  .slick-next {
    right: 10px;
  }
}
[dir='rtl'] .slick-next {
  right: auto;
  left: 5px;
}
@media (max-width: 767px) {
  [dir='rtl'] .slick-next {
    left: 10px;
  }
}
.slick-dots {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 12px;
  padding: 0 5px;
  margin: -9px 0 0 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 33.33333333333333%;
  padding: 10px 0 0 0;
  margin-left: 5px;
  cursor: pointer;
}
.slick-dots li:first-child {
  margin-left: 0;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100%;
  height: 1px;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background-color: #ddd;
  transition: all 0.3s ease;
}
.slick-dots .slick-active button {
  outline: none;
  background-color: #262626;
  height: 2px;
}
.ol {
  list-style: none;
  counter-reset: counter;
}
.ol__item {
  position: relative;
  padding-left: 1.6em;
  margin-top: 0.3em;
}
.ol__item:first-child {
  margin-top: 0;
}
.ol__item:before {
  content: counter(counter);
  counter-increment: counter;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 0.6em;
  font-weight: 700;
  border: 1px solid #ddd;
  padding: 0.3em 0.5em;
  line-height: 1;
  box-shadow: 0.5px 0.5px 0.5px rgba(0,0,0,0.15);
}
.ul {
  list-style: none;
  padding-left: 0.95em;
}
.ul__item {
  position: relative;
  padding-left: 1.4em;
  text-indent: -1.4em;
  margin-top: 0.3em;
}
.ul__item:first-child {
  margin-top: 0;
}
.ul__item:before {
  content: '・';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 1.5em;
  font-weight: 700;
}
.link-img {
  display: inline-block;
  border: none;
  transition: opacity 0.6s;
}
.link-img:hover,
.link-img:focus,
.link-img:active {
  opacity: 0.8;
}
.bookmark {
  position: relative;
  top: -73px;
}
@media (max-width: 767px) {
  .bookmark {
    top: -60px;
  }
}
.bookmark--locations {
  top: -240px;
}
@media (max-width: 767px) {
  .bookmark--locations {
    top: -105px;
  }
}
.bookmark--faq {
  top: -100px;
}
@media (max-width: 767px) {
  .bookmark--faq {
    top: -80px;
  }
}
.button {
  display: inline-block;
  padding: 13.5px;
  width: 443px;
  max-width: 100%;
  border: 2px solid #004098;
  border-radius: 33px;
  font-family: 'Heebo', sans-serif;
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
  color: #004098;
  letter-spacing: 0.06em;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .button:hover {
    background-color: #004098;
    color: #fff;
  }
}
@media (max-width: 767px) {
  .button {
    font-size: 17px;
    border-radius: 27.5px;
    padding: 12px;
    background-color: #004098;
    color: #fff;
  }
}
.button__wrapper {
  text-align: center;
}
.heading-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Patua One', serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  height: 77px;
  background: transparent url("/assets/img/heading__bg2.png") no-repeat center center;
}
@media (max-width: 767px) {
  .heading-section {
    font-size: 12px;
    height: 63px;
    background-size: 18px 63px;
  }
}
.heading-section span {
  position: relative;
  top: 4px;
  padding: 0 18px 0 19px;
  letter-spacing: 0.06em;
}
.heading-section span:before,
.heading-section span:after {
  content: '';
  display: block;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 0;
  width: 13px;
  height: 18px;
  background: transparent url("/assets/img/heading__lines.png") no-repeat 0 0;
  background-size: 13px 18px;
}
@media (max-width: 767px) {
  .heading-section span:before,
  .heading-section span:after {
    width: 11px;
    height: 15px;
    background-size: 11px 15px;
  }
}
.heading-section span:after {
  transform: translateY(-50%) scaleX(-1);
  left: initial;
  right: 0;
}
.heading-section span sup {
  font-size: 8Px;
}
.heading-section--bottle-darker {
  background: transparent url("/assets/img/heading__bg1.png") no-repeat center center;
}
@media (max-width: 767px) {
  .heading-section--bottle-darker {
    background-size: 18px 63px;
  }
}
.header-pc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 18px 0;
  z-index: 100;
  transition: top 0.4s ease;
}
@media (max-width: 767px) {
  .header-pc {
    display: none;
  }
}
.header-pc--sticky {
  top: -97px;
  background-color: #fff;
}
.header-pc--sticky.active {
  top: 0;
}
.header-sp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}
@media (min-width: 768px) {
  .header-sp {
    display: none;
  }
}
.footer {
  padding: 33px 0 36px 0;
  background-color: #004098;
  color: #fff;
}
@media (max-width: 767px) {
  .footer {
    padding: 41px 0 41px 0;
  }
}
.footer__top {
  margin-bottom: 1px;
}
@media (max-width: 767px) {
  .footer__top {
    margin-bottom: 42px;
  }
}
.footer__top__inner {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .footer__top__inner {
    display: block;
  }
}
.footer__middle {
  border-bottom: 1px solid #7190bb;
  padding-bottom: 50px;
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  .footer__middle {
    padding-bottom: 42px;
  }
}
.footer__middle__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .footer__middle__inner {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.footer__bottom {
  text-align: center;
}
.footer__bottom__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .footer__bottom__inner {
    padding: 0 15px;
  }
}
@media (max-width: 990px) {
  .footer__bottom__inner {
    display: block;
  }
}
@media (max-width: 767px) {
  .footer__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.footer__nav__link {
  color: #fff;
  margin-left: 5.4em;
  font-family: 'Patua One', serif;
  font-size: 14px;
  letter-spacing: 0.02em;
}
.footer__nav__link:first-child {
  margin-left: 0;
}
@media (max-width: 767px) {
  .footer__nav__link {
    margin: 24px 0 0 0;
    font-size: 17px;
  }
  .footer__nav__link:first-child {
    margin-top: 0;
  }
}
.footer__logo {
  margin-top: 1px;
}
@media (max-width: 767px) {
  .footer__logo {
    text-align: center;
  }
}
.footer__logo__img {
  width: 250px;
}
@media (max-width: 767px) {
  .footer__logo__img {
    width: 176px;
  }
}
.footer__logo__subtitle {
  font-size: 13px;
  margin: 15px 0 0 0;
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .footer__logo__subtitle {
    font-size: 9px;
    margin-top: 7px;
  }
}
.footer__logo__link {
  position: relative;
  display: inline-block;
  margin-top: 32px;
  padding-right: 1.5em;
  color: #fff;
  font-size: 16px;
}
.footer__logo__link:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 6px;
  height: 10px;
  background: transparent url("/assets/img/arrow--footer.png") no-repeat 0 0;
  background-size: 6px 10px;
}
@media (max-width: 767px) {
  .footer__logo__link {
    font-size: 13px;
    margin-top: 31px;
  }
}
.footer__sns {
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  .footer__sns {
    margin-bottom: 47px;
  }
}
@media (max-width: 767px) {
  .footer__sns__top {
    text-align: center;
  }
}
.footer__sns__bottom {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .footer__sns__bottom {
    margin-top: 18px;
  }
}
.footer__sns__link {
  display: inline-block;
  color: #fff;
  margin-left: 36px;
}
@media (max-width: 767px) {
  .footer__sns__link--tw {
    width: 34px;
  }
  .footer__sns__link--ig {
    width: 27px;
  }
  .footer__sns__link--fb {
    width: 27px;
  }
}
.footer__sns__link:first-child {
  margin-left: 0;
}
.footer__sns__btn {
  max-width: 190px;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .footer__sns__btn:hover {
    border-color: #fff;
  }
}
@media (max-width: 767px) {
  .footer__sns__btn {
    max-width: 198px;
    background-color: #fff;
    color: #004098;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.footer__outbound-nav {
  text-align: left;
}
@media (max-width: 990px) {
  .footer__outbound-nav {
    text-align: center;
  }
}
.footer__outbound-nav__link {
  position: relative;
  color: #fff;
  margin-right: 4.5em;
  padding-right: 1.95em;
  font-size: 11px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .footer__outbound-nav__link {
    font-size: 10px;
    padding-right: 0.95em;
    margin-right: 4.1em;
  }
}
.footer__outbound-nav__link:last-child {
  margin-right: 0;
}
.footer__outbound-nav__link:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 4px;
  height: 7px;
  background: transparent url("/assets/img/arrow--footer.png") no-repeat 0 0;
  background-size: 4px 7px;
}
.footer__outbound-nav__link sup {
  font-size: 7Px;
}
.footer__copyright {
  display: flex;
  margin: 0 0 3px 0;
}
@media (max-width: 990px) {
  .footer__copyright {
    display: block;
    text-align: center;
    margin: 17px;
  }
}
@media (max-width: 767px) {
  .footer__copyright {
    margin-top: 17px;
  }
}
.footer__copyright__text {
  margin: 0 0 0 5.6em;
  text-align: center;
  font-size: 9px;
}
@media (max-width: 1158px) {
  .footer__copyright__text {
    margin-left: 4.35vw;
  }
}
@media (max-width: 990px) {
  .footer__copyright__text {
    margin: 8px 0 0 0;
  }
}
.footer__copyright__text:first-child {
  margin-left: 0;
}
.nav-pc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-pc__logo {
  display: block;
  width: 134px;
}
.nav-pc__list {
  display: block;
}
.nav-pc__item {
  display: inline-block;
  margin-left: 2.9em;
}
.nav-pc__item:first-child {
  margin-left: 0;
}
.nav-pc__link {
  position: relative;
  color: #fff;
  font-family: 'Patua One', serif;
  font-weight: 400;
  transition: color 0.5s ease-in-out;
}
.nav-pc__link:before,
.nav-pc__link:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -6px;
  height: 2px;
  width: 0;
  background-color: #fff;
}
.nav-pc__link:before {
  right: 0;
  transition: width 0.4s cubic-bezier(0.29, 0.18, 0.26, 0.83);
}
.nav-pc__link:after {
  left: 0;
}
.nav-pc__link:hover {
  opacity: 1;
}
.nav-pc__link:hover:before {
  width: 100%;
  left: 0;
}
.nav-pc__link--sns {
  margin-left: 14px;
  opacity: 1;
  transition: opacity 0.3s;
}
.nav-pc__link--sns:hover,
.nav-pc__link--sns:focus,
.nav-pc__link--sns:active {
  opacity: 0.8;
}
.nav-pc__link--sns:first-child {
  margin-left: 0;
}
.nav-pc__link--sns:before,
.nav-pc__link--sns:after {
  display: none;
}
.header-pc--sticky .nav-pc__link {
  color: #262626;
}
.header-pc--sticky .nav-pc__link:before,
.header-pc--sticky .nav-pc__link:after {
  background-color: #262626;
}
.nav-sp {
  display: flex;
  justify-content: space-between;
  padding: 17px 0;
}
.nav-sp__logo {
  width: 92.5px;
}
.nav-sp__toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: block;
  width: 25px;
  height: 21px;
  color: #e95279;
  transition: color 0.5s ease-in-out;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.nav-sp__toggle__wrapper {
  position: relative;
  flex-grow: 1;
  text-align: right;
}
.nav-sp__toggle__bar {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: #000;
  transition: all 0.25s ease-in-out;
}
.nav-sp__toggle__bar:first-child {
  top: 0;
  transition-delay: 0.5s;
}
.nav-sp__toggle__bar:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  transition-delay: 0.625s;
}
.nav-sp__toggle__bar:last-child {
  bottom: 0;
  transition-delay: 0.75s;
}
.nav-sp__overlay {
  display: block;
  position: fixed;
  top: -100vh;
  right: 0;
  width: 100%;
  height: 62.5vh;
  background-color: #fff;
  z-index: 1000;
  transition: top 0.8s ease-in-out;
}
@media (min-width: 768px) {
  .nav-sp__overlay {
    display: none;
  }
}
.nav-sp__overlay__logo {
  width: 92.5px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transition-delay: 1.25s;
}
.nav-sp__overlay__logo.active {
  opacity: 1;
}
.nav-sp__overlay__top {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 17px 0;
}
.nav-sp__overlay__close {
  display: block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 25px;
  height: 26px;
  display: block;
  outline: 0;
  color: #000;
  transform: rotate(45deg);
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.nav-sp__overlay__close__bar {
  position: absolute;
  display: block;
  background-color: #000;
  transition: all 0.25s ease-in-out;
}
.nav-sp__overlay__close__bar:first-child {
  transition-delay: 1s;
  height: 0;
  width: 2px;
  left: 11px;
}
.nav-sp__overlay__close__bar:last-child {
  transition-delay: 1.3s;
  height: 2px;
  width: 0;
  top: 11px;
}
.nav-sp__overlay__inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 19px auto 0 auto;
  text-align: center;
}
.nav-sp__overlay__link {
  display: block;
  margin-top: 3.5vh;
  font-family: 'Patua One', serif;
  font-size: 19px;
  font-weight: 400;
  color: #000;
  opacity: 0;
  transform: translateX(2rem);
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.nav-sp__overlay__link:hover,
.nav-sp__overlay__link:active,
.nav-sp__overlay__link:focus {
  color: #000;
  opacity: 0;
}
.nav-sp__overlay__link:first-child {
  margin-top: 0;
}
.nav-sp__overlay__sns {
  display: flex;
  justify-content: center;
  margin-top: 7.4vh;
  opacity: 0;
  transform: translateX(2rem);
}
.nav-sp__overlay__sns__link {
  margin-left: 30px;
}
.nav-sp__overlay__sns__link:first-child {
  margin-left: -7px;
}
.nav-sp__overlay.active {
  top: 0;
}
.nav-sp__overlay.active .nav-sp__overlay__link {
  animation: mobileNavItemsSlideIn 300ms ease forwards;
}
.nav-sp__overlay.active .nav-sp__overlay__link:nth-child(1) {
  animation-delay: 0.9s;
}
.nav-sp__overlay.active .nav-sp__overlay__link:nth-child(2) {
  animation-delay: 0.95s;
}
.nav-sp__overlay.active .nav-sp__overlay__link:nth-child(3) {
  animation-delay: 1s;
}
.nav-sp__overlay.active .nav-sp__overlay__link:nth-child(4) {
  animation-delay: 1.05s;
}
.nav-sp__overlay.active .nav-sp__overlay__link:nth-child(5) {
  animation-delay: 1.1s;
}
.nav-sp__overlay.active .nav-sp__overlay__link:nth-child(6) {
  animation-delay: 1.15s;
}
.nav-sp__overlay.active .nav-sp__overlay__sns {
  animation: mobileNavItemsSlideIn 300ms ease forwards;
  animation-delay: 1.2s;
}
.nav-sp__overlay.active .nav-sp__overlay__close__bar:first-child {
  height: 100%;
}
.nav-sp__overlay.active .nav-sp__overlay__close__bar:last-child {
  width: 100%;
}
.nav-sp__overlay.active .nav-sp__overlay__logo {
  opacity: 1;
}
@media (max-width: 767px) {
  .hero {
    padding-top: 59px;
  }
}
.hero__inner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url("/assets/img/hero__bg.jpg");
}
@media (max-width: 767px) {
  .hero__inner {
    background-image: url("/assets/img/sp/hero__bg.jpg");
  }
}
.hero__title {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 84%;
  text-align: center;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 1.8s ease;
  transition-delay: 0.7s;
}
.hero__title.active {
  opacity: 1;
}
@media (max-width: 767px) {
  .hero__title {
    width: 78%;
    margin: 0;
    right: initial;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
  }
}
.hero__title__text {
  display: block;
  margin: 0;
  font-size: 104px;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 0 52px rgba(0,0,0,0.3);
  color: #fff;
}
.hero__title picture {
  width: 100%;
}
.hero__preload-bg {
  display: none;
}
.intro {
  background-image: url("/assets/img/intro__bg.jpg");
}
@media (max-width: 767px) {
  .intro {
    position: relative;
    height: 860px;
    background-image: url("/assets/img/sp/intro__bg.jpg");
    background-position: bottom center;
  }
}
@media (min-width: 375px) and (max-width: 767px) {
  .intro {
    height: 795px;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .intro {
    height: 823px;
  }
}
@media (min-width: 485px) and (max-width: 767px) {
  .intro {
    height: 850px;
  }
}
@media (min-width: 550px) and (max-width: 767px) {
  .intro {
    height: 890px;
  }
}
@media (min-width: 630px) and (max-width: 767px) {
  .intro {
    height: 910px;
  }
}
@media (min-width: 690px) and (max-width: 767px) {
  .intro {
    height: 970px;
  }
}
.intro__title {
  position: relative;
  top: 15.2vh;
}
@media (max-width: 767px) {
  .intro__title {
    top: 80px;
  }
}
.intro__text {
  position: absolute;
  top: 39vh;
  right: 7vw;
  width: 43vw;
}
@media (max-width: 767px) {
  .intro__text {
    width: 90vw;
    top: 164px;
    right: initial;
    left: 19px;
  }
}
.intro__text__title {
  font-size: 46px;
  font-weight: 700;
  font-style: italic;
  margin: 0;
  line-height: 1.1;
}
@media (max-width: 767px) {
  .intro__text__title {
    font-size: 32px;
  }
}
.intro__text__body {
  font-size: 15px;
  margin: 23px 0 0 0;
  line-height: 1.7;
}
@media (max-width: 767px) {
  .intro__text__body {
    font-size: 13px;
    margin: 12px 0 0 0;
    line-height: 1.65;
  }
}
.products {
  padding: 117px 0 170px 0;
}
@media (max-width: 767px) {
  .products {
    padding: 79px 0 102px 0;
  }
}
@media (max-width: 767px) {
  .products .wrapper {
    padding: 0 6px 0 6px;
  }
}
.products__section {
  margin-top: 18px;
}
.products__section__title {
  text-align: center;
  margin: 0;
  font-size: 24px;
}
@media (max-width: 767px) {
  .products__section__title {
    font-size: 16px;
    margin-top: 24px;
  }
}
.products__section__subtitle {
  text-align: center;
  font-size: 14px;
  margin: 4px 0 0 0;
}
@media (max-width: 767px) {
  .products__section__subtitle {
    font-size: 13px;
    margin-top: 2px;
  }
}
.products__sliders {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 69px 0 0 0;
}
@media (max-width: 767px) {
  .products__sliders {
    margin-top: 41px;
  }
}
.products__sliders:first-child {
  margin-top: 0;
}
.products__sliders__wrapper {
  margin-top: 23px;
}
@media (max-width: 767px) {
  .products__sliders__wrapper {
    margin-top: 8px;
  }
}
.products__slider {
  display: flex;
  flex-direction: column;
  width: 33.33333333333333%;
  padding: 33px 3px 0 3px;
}
@media (max-width: 767px) {
  .products__slider {
    width: 50%;
    padding-top: 29px;
  }
}
.products__slider:nth-child(1),
.products__slider:nth-child(2),
.products__slider:nth-child(3) {
  padding-top: 0;
}
@media (max-width: 767px) {
  .products__slider:nth-child(1),
  .products__slider:nth-child(2),
  .products__slider:nth-child(3) {
    padding-top: 29px;
  }
  .products__slider:nth-child(1):nth-child(1),
  .products__slider:nth-child(2):nth-child(1),
  .products__slider:nth-child(3):nth-child(1),
  .products__slider:nth-child(1):nth-child(2),
  .products__slider:nth-child(2):nth-child(2),
  .products__slider:nth-child(3):nth-child(2) {
    padding-top: 0;
  }
}
.products__slider__inner {
  margin-top: auto;
}
.products__slider__title {
  margin: 0 0 5px 0;
  font-size: 24px;
  text-align: center;
}
@media (max-width: 767px) {
  .products__slider__title {
    font-size: 16px;
    margin-bottom: 1px;
  }
}
.products__slider__top {
  margin-bottom: 23px;
}
@media (max-width: 767px) {
  .products__slider__top {
    margin-bottom: 10px;
  }
}
.products__slider__subtitle {
  margin: 0;
  font-size: 14px;
  text-align: center;
}
@media (max-width: 767px) {
  .products__slider__subtitle {
    font-size: 13px;
  }
}
.products__slider__caption {
  text-align: center;
  margin: 15px 0 0 0;
  font-size: 15px;
  letter-spacing: 0.04em;
}
@media (max-width: 767px) {
  .products__slider__caption {
    font-size: 12px;
    margin-top: 11px;
  }
}
.products__slide {
  position: relative;
}
.products__slide--border {
  padding: 1px;
}
.products__slide--border>img,
.products__slide--border .products__slide__desc {
  border: 1px solid #e1e1e1;
}
.products__slide__desc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 36px 44px;
  background-color: rgba(255,255,255,0.9);
}
@media (max-width: 767px) {
  .products__slide__desc {
    padding: 14px 15px;
  }
}
.products__slide__desc--narrow {
  padding-top: 27px;
  padding-bottom: 27px;
}
@media (max-width: 767px) {
  .products__slide__desc--narrow {
    padding: 14px 15px;
  }
}
.products__slide__desc--original {
  background-color: rgba(190,214,220,0.9);
}
.products__slide__desc--mango {
  background-color: rgba(246,223,0,0.9);
}
.products__slide__desc--lychee {
  background-color: rgba(241,197,193,0.9);
}
.products__slide__desc--lychee .products__slide__desc__title,
.products__slide__desc--lychee .products__slide__desc__text {
  color: $color-bg-white;
}
.products__slide__desc--white-peach {
  background-color: rgba(236,220,210,0.9);
}
.products__slide__desc--strawberry {
  background-color: rgba(246,215,218,0.9);
}
.products__slide__desc__inner {
  height: 100%;
}
.products__slide__desc__title {
  font-size: 15px;
  color: #262626;
  font-weight: 700;
  margin: 0;
}
@media (max-width: 1158px) {
  .products__slide__desc__title {
    font-size: 1.295335vw;
  }
}
@media (max-width: 767px) {
  .products__slide__desc__title {
    font-size: 11px;
    font-size: 2.93335vw;
    text-align: center;
  }
}
.products__slide__desc__text {
  font-size: 15px;
  color: #262626;
  margin: 12px 0 0 0;
  line-height: 1.35;
}
@media (max-width: 1158px) {
  .products__slide__desc__text {
    font-size: 1.295335vw;
  }
}
@media (max-width: 767px) {
  .products__slide__desc__text {
    font-size: 11px;
    font-size: 2.93335vw;
    margin-top: 9px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .products__slide__desc__text--concentrate {
    font-size: 2.63335vw;
    margin-top: 6px;
  }
}
@media (max-width: 767px) {
  .products__slide__desc__text--mango {
    font-size: 2.67vw;
  }
}
@media (max-width: 767px) {
  .products__slide__desc__text--strawberry {
    font-size: 2.67vw;
  }
}
.products__slide__desc__text__img {
  margin-top: 5px;
}
@media (max-width: 1158px) {
  .products__slide__desc__text__img {
    width: 84%;
  }
}
@media (max-width: 548px) {
  .products__slide__desc__text__img {
    width: 65%;
  }
}
.products__slide .button {
  margin-top: 20px;
  border-color: #000;
  border-width: 1px;
  width: 100%;
  font-size: 16px;
  color: #000;
  background-color: transparent;
  text-transform: capitalize;
  padding: 8px;
  transition: color 0.3s;
}
@media (min-width: 768px) {
  .products__slide .button:hover {
    color: #333;
  }
}
@media (max-width: 767px) {
  .products__slide .button {
    font-size: 2.93335vw;
    padding: 2px;
    margin-top: 3px;
  }
}
.products__cta {
  margin-top: 73px;
}
@media (max-width: 767px) {
  .products__cta {
    margin-top: 44px;
    padding: 0 15px;
  }
}
.locations {
  background: #fff url("/assets/img/locations__bg1-slice.jpg") repeat-x 0 0;
  background-size: 1px 860px;
}
@media (max-width: 767px) {
  .locations {
    background: #fff;
  }
}
.locations__bg {
  background: transparent url("/assets/img/locations__bg1.jpg") no-repeat 50% 0;
  background-size: 1400px 860px;
}
@media (max-width: 767px) {
  .locations__bg {
    background: transparent url("/assets/img/sp/locations__bg1.jpg") no-repeat 50% 0;
    background-size: contain;
  }
}
.locations__inner {
  position: relative;
  top: -155px;
  margin-bottom: -52px;
  padding: 528px 0 0 0;
  background: transparent url("/assets/img/locations__bg2.png") no-repeat top center;
  background-size: 1400px 695px;
}
@media (max-width: 767px) {
  .locations__inner {
    top: -39px;
    padding-top: 51vw;
    padding-top: 191px;
    margin-bottom: 0;
    background: transparent url("/assets/img/sp/locations__bg2.png") no-repeat top center;
    background-size: 375px 195px;
  }
}
@media (max-width: 767px) {
  .locations__inner .wrapper {
    padding: 0 9px 0 9px;
  }
}
.locations__section {
  background-color: #fff;
  padding: 43px 70px 0 70px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .locations__section {
    border-radius: 20px;
    padding: 30px 11px 0 11px;
  }
}
.locations__section__title {
  font-family: 'Heebo', sans-serif;
  font-style: italic;
  font-size: 36px;
  text-align: center;
  margin: 15px 0 0 0;
  letter-spacing: 0.028em;
}
@media (max-width: 767px) {
  .locations__section__title {
    font-size: 23px;
    margin-top: 24px;
  }
}
.locations__locator {
  margin: 32px 0 0 0;
}
@media (max-width: 767px) {
  .locations__locator {
    margin-top: 20px;
  }
}
.locations__locator__widget {
  width: 100%;
}
.locations__cta {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .locations__cta {
    margin-top: 51px;
  }
}
.faq {
  padding: 14px 0 0 0;
}
@media (max-width: 767px) {
  .faq {
    padding: 40px 0 0 0;
  }
}
.faq__section__title {
  font-family: 'Heebo', sans-serif;
  font-style: italic;
  font-size: 36px;
  text-align: center;
  margin: 15px 0 0 0;
  letter-spacing: 0.028em;
}
@media (max-width: 767px) {
  .faq__section__title {
    font-size: 23px;
    margin-top: 24px;
  }
}
.faq__section__subtitle {
  text-align: center;
}
.faq__acrs {
  margin-top: 49px;
}
@media (max-width: 767px) {
  .faq__acrs {
    margin-top: 27px;
  }
}
.faq__acr {
  border-top: 1px solid #979797;
  padding-top: 25px;
  margin-top: 25px;
}
@media (max-width: 767px) {
  .faq__acr {
    padding-top: 15px;
    margin-top: 15px;
  }
}
.faq__acr:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}
.faq__acr:last-child {
  border-bottom: 1px solid #979797;
  padding-bottom: 25px;
}
@media (max-width: 767px) {
  .faq__acr:last-child {
    padding-bottom: 15px;
  }
}
.faq__acr__title {
  position: relative;
  margin: 0;
  font-family: 'Heebo', sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding-right: 22px;
  color: #262626;
  cursor: pointer;
}
@media (min-width: 768px) {
  .faq__acr__title {
    transition: color 0.3s;
  }
  .faq__acr__title:hover,
  .faq__acr__title:focus,
  .faq__acr__title:active {
    color: #004098;
  }
}
@media (max-width: 767px) {
  .faq__acr__title {
    font-size: 16px;
    line-height: 1.5;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
}
.faq__acr__title:before,
.faq__acr__title:after {
  content: '';
  position: absolute;
  top: 11px;
  right: 0;
  height: 1px;
  width: 16px;
  transform: rotate(180deg);
  background-color: #262626;
  transition: transform 0.3s ease;
}
.faq__acr__title:after {
  transform-origin: center;
  transform: rotate(90deg);
}
.faq__acr__title.active:after {
  transform: rotate(0);
}
.faq__acr__title.active:before {
  transform: rotate(0);
}
.faq__acr__title sup {
  top: 0;
}
.faq__acr__body {
  display: none;
  font-size: 16px;
  margin: 27px 0 0 0;
  line-height: 1.9;
}
@media (max-width: 767px) {
  .faq__acr__body {
    margin-top: 15px;
    font-size: 13px;
    line-height: 1.65;
  }
}
.faq__acr__body--small {
  font-size: 12px;
  line-height: 1.7;
  letter-spacing: 0.054em;
  margin-top: 9px;
}
@media (max-width: 767px) {
  .faq__acr__body--small {
    font-size: 13px;
  }
}
.instagram {
  padding: 117px 0 140px 0;
}
@media (max-width: 767px) {
  .instagram {
    padding: 78px 0 107px 0;
  }
}
@media (max-width: 767px) {
  .instagram .wrapper {
    padding: 0 9px 0 9px;
  }
}
.instagram__section__title {
  font-family: 'Heebo', sans-serif;
  font-style: italic;
  font-size: 36px;
  text-align: center;
  margin: 15px 0 0 0;
  letter-spacing: 0.028em;
}
@media (max-width: 767px) {
  .instagram__section__title {
    font-size: 23px;
    margin-top: 24px;
  }
}
.instagram__feed {
  display: grid;
  margin: 31px 0 0 0;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram__feed {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
  }
}
@media (max-width: 767px) {
  .instagram__feed {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
    margin-top: 22px;
  }
}
.instagram__feed a {
  display: block;
  border: 0;
  height: 217.59px;
}
@media (max-width: 1158px) {
  .instagram__feed a {
    height: 18.791vw;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram__feed a {
    flex-basis: initial;
    width: 20%;
    padding: 5px;
  }
}
.instagram__feed a:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/3;
  height: 445.19px;
}
@media (max-width: 1158px) {
  .instagram__feed a:nth-child(1) {
    height: 38.445vw;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram__feed a:nth-child(1) {
    height: 217.59px;
  }
}
.instagram__feed a:nth-child(14) {
  grid-column: 4/6;
  grid-row: 3/5;
  height: 445.19px;
}
@media (max-width: 1158px) {
  .instagram__feed a:nth-child(14) {
    height: 38.445vw;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram__feed a:nth-child(14) {
    height: 217.59px;
  }
}
.instagram__feed a:nth-child(15) {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram__feed a:nth-child(15) {
    display: block;
  }
}
@media (max-width: 767px) {
  .instagram__feed a {
    display: none;
    flex-basis: 50%;
    padding: 2.5px;
    height: 48.2vw !important;
  }
  .instagram__feed a:nth-child(odd) {
    padding-left: 0;
  }
  .instagram__feed a:nth-child(even) {
    padding-right: 0;
  }
  .instagram__feed a:nth-child(-n+6) {
    display: block;
  }
}
@media screen and (max-width: 767px) and (-ms-high-contrast: active), (max-width: 767px) and (-ms-high-contrast: none) {
  .instagram__feed a {
    flex-basis: initial;
    width: 50%;
  }
}
@media screen and (max-width: 767px) and (-ms-high-contrast: active), (max-width: 767px) and (-ms-high-contrast: none) {
  .instagram__feed a:nth-child(15) {
    display: none;
  }
}
.instagram__feed img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.u-centered {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.u-centered-x {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.u-centered-y {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.u-hidden {
  margin: -1px !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  clip: rect(0, 0, 0, 0) !important;
  position: absolute !important;
}
.u-ellipsis {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.hidden {
  visibility: hidden;
}
.remove {
  display: none;
}
.u-simple-cf {
  overflow: hidden;
}
.u-clearfix {
  zoom: 1 !important;
}
.u-clearfix:before,
.u-clearfix:after {
  content: "\0020" !important;
  display: block !important;
  height: 0 !important;
  overflow: hidden !important;
}
.u-clearfix:after {
  clear: both !important;
}
@media (min-width: 768px) {
  .desktop-only {
    display: block !important;
  }
  .mobile-only {
    display: none !important;
  }
  .desktop-only-i {
    display: inline !important;
  }
  .mobile-only-i {
    display: none !important;
  }
  .desktop-only-ib {
    display: inline-block !important;
  }
  .mobile-only-ib {
    display: none !important;
  }
  .desktop-only-tc {
    display: table-cell !important;
  }
  .mobile-only-tc {
    display: none !important;
  }
  .desktop-only-fb {
    display: flex !important;
  }
  .mobile-only-fb {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .mobile-only {
    display: block !important;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only-i {
    display: inline !important;
  }
  .desktop-only-i {
    display: none !important;
  }
  .mobile-only-ib {
    display: inline-block !important;
  }
  .desktop-only-ib {
    display: none !important;
  }
  .mobile-only-tc {
    display: table-cell !important;
  }
  .desktop-only-tc {
    display: none !important;
  }
  .mobile-only-fb {
    display: flex !important;
  }
  .desktop-only-fb {
    display: none !important;
  }
}
