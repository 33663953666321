/* Slider */

.slick-slider
  position relative
  display block
  box-sizing border-box
  -webkit-touch-callout none
  -webkit-user-select none
  -khtml-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  -ms-touch-action pan-y
  touch-action pan-y
  -webkit-tap-highlight-color transparent

.slick-list
  position relative
  overflow hidden
  display block
  margin 0
  padding 0

  &:focus
    outline none


  &.dragging
    cursor pointer
    cursor hand


.slick-slider .slick-track,
.slick-slider .slick-list
  transform translate3d(0, 0, 0)


.slick-track
  position relative
  left 0
  top 0
  display block
  margin-left auto
  margin-right auto

  &:before,
  &:after
    content ""
    display table


  &:after
    clear both


  .slick-loading &
    visibility hidden


.slick-slide
  float left
  height 100%
  min-height 1px
  [dir="rtl"] &
    float right

  img
    display block

  &.slick-loading img
    display none


  display none

  &.dragging img
    pointer-events none


  .slick-initialized &
    display block


  .slick-loading &
    visibility hidden


  .slick-vertical &
    display block
    height auto
    border 1px solid transparent


.slick-arrow.slick-hidden
  display none



/* Slider theme */

/* Arrows */
.slick-prev,
.slick-next
  font-size 0
  line-height 0

  position absolute
  top 46%

  display block

  width 21px
  height 52px
  padding 0
  transform translate(0, -50%)

  cursor pointer

  color transparent
  border none
  outline none
  background transparent

  overflow hidden

  z-index 1
  +maxw($small-max)
    width 30px
    height 20px
    top 15px

.slick-prev:hover,
.slick-next:hover,
  color transparent
  outline none
  background transparent

.slick-prev:hover:before,
.slick-next:hover:before,
  opacity 1

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
  opacity .25


.slick-prev:before,
.slick-next:before
  content ''
  position absolute
  top 0
  left 0
  padding 8px
  box-shadow 1px -1px 0 1px $color-slider-arrow inset
  -webkit-box-shadow 1.5px -1.5px $color-slider-arrow inset
  border solid transparent
  border-width 0 0 2rem 2rem
  transition 0.2s
  transform rotate(225deg)
  opacity .8
  +maxw($small-max)
    left initial
    right 0
    top 5px
    width 16px
    height 10px
    padding 0
    box-shadow none
    -webkit-box-shadow none
    border 0
    transform none
    background-position 0 0
    background-repeat no-repeat
    background-size 16px 10px
    background-image url('/assets/img/sp/slider__arrow.png')

.slick-prev:before
  transform rotate(45deg)
  left initial
  right 0
  +maxw($small-max)
    transform scaleX(-1)
    right initial
    left 0

.slick-prev
  left 5px
  +maxw($small-max)
    left 10px

[dir='rtl'] .slick-prev

  right 5px
  left auto
  +maxw($small-max)
    left 10px


.slick-next
  right 5px
  +maxw($small-max)
    right 10px

[dir='rtl'] .slick-next
  right auto
  left 5px
  +maxw($small-max)
    left 10px


/* Dots */

.slick-dots
  display flex
  align-items flex-end

  width 100%
  height 12px
  padding 0 5px
  margin -9px 0 0 0

  list-style none

  text-align center

.slick-dots li
  position relative

  display inline-block

  width percentage(1/3)
  padding 10px 0 0 0
  margin-left 5px

  cursor pointer

.slick-dots li:first-child
  margin-left 0

.slick-dots li button
  font-size 0
  line-height 0

  display block

  width 100%
  height 1px

  border-radius 0

  padding 0

  cursor pointer

  color transparent
  border 0
  outline none
  background-color $color-slider-dots-inactive

  transition all .3s ease

.slick-dots .slick-active button
  outline none
  background-color $color-slider-dots
  height 2px