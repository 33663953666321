//  Base

html
  font-size $font-size-base

body
  -webkit-font-smoothing antialiased
  -webkit-text-size-adjust 100%
  text-rendering optimizeLegibility
  font-family $font-family
  font-weight 400
  line-height $line-height-base
  color $color-text
  +maxw($small-max)
    font-size $font-size-small

//  Headings

.gigantic, .huge, .large, .bigger, .big,
h1, h2, h3, h4, h5, h6
  margin 1.414em 0 0.5em
  color $color-text
  font-family $font-family
  font-weight 700
  line-height 1.2

.huge, h1
  font-size 39.056px
  margin-top 0
  +maxw($small-max)
    font-size 33.184px

.large, h2
  font-size 31.248px
  +maxw($small-max)
    font-size 27.648px

.bigger, h3
  font-size 25.008px
  +maxw($small-max)
    font-size 23.04px

.big, h4
  font-size 20px
  +maxw($small-max)
    font-size 19.2px

.small, small
  font-size 12.8px
  +maxw($small-max)
    font-size 13.328px


// Basic Text Style

p
  margin 0 0 1.3em 0
  font-weight 400

em
  font-style italic

strong
  font-weight bold

sup
  position relative
  margin-left .15em
  top .18em
  font-size .5em
