@keyframes mobileNavItemsSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
    transform: translateX(3rem)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}