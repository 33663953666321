::selection
  background lighten($color-primary, 45)

::-moz-selection
  background lighten($color-primary, 45)

img::selection
  background transparent

img::-moz-selection
  background transparent

body
  -webkit-tap-highlight-color lighten($color-primary, 45)
